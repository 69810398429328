<template>
  <payroll-report
    :userIdProp="targetUser.id"
    :isAdminProp="true"
  ></payroll-report>
</template>

<script>
import { mapState } from "vuex";
import PayrollReport from "../reports/Payroll.vue";

export default {
  name: "adminPayroll",
  components: {
    PayrollReport,
  },
  computed: {
    ...mapState({
      targetUser: (state) => state.Profile.user,
    }),
  },
};
</script>
